c-box<template>
  <c-box flex-grow="1">
    <BreadcrumbPath
      margin-bottom="20px"
      :paths="[{ label: 'Dashboard', isCurrent: true }]"
    />
    <c-box
      width="100%"
      max-width="1270px"
      background-color="#FFF"
      margin-bottom="16px"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      :padding="['16px', '30px']"
      :min-height="['unset', '74vh']"
    >
      <c-box
        display="flex"
        flex-direction="column"
        gap="30px"
        padding="0 40px"
      >
        <c-box text-align="center">
          <BaseText
            size-mobile="20px"
            size-desktop="28px"
          >
            Dashboard
          </BaseText>
        </c-box>

        <!-- Client Tracking -->
        <TransitionObserver
          :with-opacity="false"
          :debounce-delay="3000"
          @on-intersect="fetchOnIntersect('CLIENTS_TRACKING')"
        >
          <c-box
            display="flex"
            flex-direction="column"
            gap="18px"
            margin-top="6px"
          >
            <c-box
              display="flex"
              justify-content="space-between"
              align-items="center"
            >
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
              >
                Clients Tracking
              </BaseText>
              <c-flex gap="16px">
                <BaseMenu trigger="manual">
                  <template #button="{ toggle }">
                    <c-button
                      variant-color="gray"
                      variant="outline"
                      border-radius="1000px"
                      color="primary.400"
                      @click="toggle"
                    >
                      <c-box margin-right="8px">
                        <inline-svg
                          :src="require('@/assets/icons/icon-filter.svg')"
                          height="17"
                          width="17"
                          fill="#008C81"
                        />
                      </c-box>
                      Filter
                    </c-button>
                  </template>
                  <template #menu="{ close }">
                    <c-box
                      z-index="10"
                      min-width="300px"
                      px="24px"
                      padding-bottom="12px"
                      mt="24px"
                    >
                      <c-box text-align="start">
                        <BaseInputSelect
                          v-model="filterStatus"
                          placeholder="Pilih Status"
                          label="Status Pembayaran"
                          size="sm"
                          :options="[
                            { value: 'done', label: 'Done' },
                            { value: 'pending', label: 'Pending' },
                            { value: 'failed', label: 'Failed' },
                            { value: 'expire', label: 'Expire' },
                          ]"
                        />
                        <BaseInputDate
                          v-model="filterDate"
                          label="Tanggal"
                          clearable
                          height="48px"
                          placeholder="Pilih Tanggal"
                          size="sm"
                        />
                      </c-box>
                      <c-menu-divider />
                      <c-flex
                        justify="flex-end"
                        padding="0 8px"
                      >
                        <BaseButton
                          border-radius="1000px"
                          size="small"
                          padding="0 24px"
                          @click="
                            () => {
                              close()
                              applyFilterClientTracking()
                            }
                          "
                        >
                          Simpan
                        </BaseButton>
                      </c-flex>
                    </c-box>
                  </template>
                </BaseMenu>

                <c-flex align="center">
                  <BaseText
                    size-mobile="14px-2"
                    size-desktop="16px"
                  >
                    Show
                  </BaseText>
                  <c-select
                    min-width="140px"
                    border-radius="8px"
                    size="md"
                    color="primary.400"
                    font-size="18px"
                    font-weight="500"
                    margin-left="16px"
                    :value="clientTracking.meta?.pagesize?.toString()"
                    @change="(perPage) => onChangePerPage('CLIENTS_TRACKING', +perPage)"
                  >
                    <option value="5">
                      5 Rows
                    </option>
                    <option value="10">
                      10 Rows
                    </option>
                    <option value="15">
                      15 Rows
                    </option>
                    <option value="20">
                      20 Rows
                    </option>
                  </c-select>
                </c-flex>
              </c-flex>
            </c-box>
            <BaseTable
              :columns="clientTracking.columns"
              :items="clientTracking.items"
              :page="clientTracking.meta?.page"
              :per-page="clientTracking.meta?.pagesize"
              :total-page="calculatePerPage(clientTracking?.total, clientTracking.meta?.pagesize)"
              :sort="clientTracking.meta?.sort"
              :is-loading="clientTracking.isLoading"
              min-height="500px"
              @on-change-page="(page) => onChangePage('CLIENTS_TRACKING', page)"
              @on-change-sort="(sort) => onChangeSort('CLIENTS_TRACKING', sort)"
            />
          </c-box>
        </TransitionObserver>

        <!-- Nutritionist Tracking -->
        <TransitionObserver
          :with-opacity="false"
          :debounce-delay="3000"
          @on-intersect="fetchOnIntersect('NUTRITIONIST_TRACKING')"
        >
          <c-box
            display="flex"
            flex-direction="column"
            gap="18px"
            margin-top="6px"
          >
            <c-box
              display="flex"
              justify-content="space-between"
              align-items="center"
            >
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
              >
                Nutritionist Tracking
              </BaseText>
              <c-flex align="center">
                <BaseText
                  size-mobile="14px-2"
                  size-desktop="16px"
                >
                  Show
                </BaseText>
                <c-select
                  min-width="140px"
                  border-radius="8px"
                  size="md"
                  color="primary.400"
                  font-size="18px"
                  font-weight="500"
                  margin-left="16px"
                  :value="nutritionistsTracking.meta?.pagesize?.toString()"
                  @change="(perPage) => onChangePerPage('NUTRITIONIST_TRACKING', +perPage)"
                >
                  <option value="5">
                    5 Rows
                  </option>
                  <option value="10">
                    10 Rows
                  </option>
                  <option value="15">
                    15 Rows
                  </option>
                  <option value="20">
                    20 Rows
                  </option>
                </c-select>
              </c-flex>
            </c-box>
            <BaseTable
              :columns="nutritionistsTracking.columns"
              :items="nutritionistsTracking.items"
              :page="nutritionistsTracking.meta?.page"
              :per-page="nutritionistsTracking.meta?.pagesize"
              :total-page="calculatePerPage(nutritionistsTracking?.total, nutritionistsTracking.meta?.pagesize)"
              :is-loading="nutritionistsTracking.isLoading"
              min-height="500px"
              @on-change-page="(page) => onChangePage('NUTRITIONIST_TRACKING', page)"
              @on-change-sort="(sort) => onChangeSort('NUTRITIONIST_TRACKING', sort)"
            />
          </c-box>
        </TransitionObserver>

        <!-- Payment Tracking -->
        <TransitionObserver
          :with-opacity="false"
          :debounce-delay="3000"
          @on-intersect="fetchOnIntersect('PAYMENT_TRACKING')"
        >
          <c-box
            display="flex"
            flex-direction="column"
            gap="18px"
            margin-top="6px"
          >
            <c-box
              display="flex"
              justify-content="space-between"
              align-items="center"
            >
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
              >
                Payment Tracking
              </BaseText>
              <c-flex gap="16px">
                <BaseMenu trigger="manual">
                  <template #button="{ toggle }">
                    <c-button
                      variant-color="gray"
                      variant="outline"
                      border-radius="1000px"
                      color="primary.400"
                      @click="toggle"
                    >
                      <c-box margin-right="8px">
                        <inline-svg
                          :src="require('@/assets/icons/icon-filter.svg')"
                          height="17"
                          width="17"
                          fill="#008C81"
                        />
                      </c-box>
                      Filter
                    </c-button>
                  </template>
                  <template #menu="{ close }">
                    <c-box
                      z-index="10"
                      min-width="300px"
                      px="24px"
                      padding-bottom="12px"
                      mt="24px"
                    >
                      <c-box text-align="start">
                        <BaseInputDate
                          v-model="filterDatePaymentTracking"
                          label="Tanggal"
                          clearable
                          height="48px"
                          placeholder="Pilih Tanggal"
                          size="sm"
                        />
                      </c-box>
                      <c-menu-divider />
                      <c-flex
                        justify="flex-end"
                        padding="0 8px"
                      >
                        <BaseButton
                          border-radius="1000px"
                          size="small"
                          padding="0 24px"
                          @click="
                            () => {
                              close()
                              applyFilterPaymentTracking()
                            }
                          "
                        >
                          Simpan
                        </BaseButton>
                      </c-flex>
                    </c-box>
                  </template>
                </BaseMenu>
                <c-flex align="center">
                  <BaseText
                    size-mobile="14px-2"
                    size-desktop="16px"
                  >
                    Show
                  </BaseText>
                  <c-select
                    min-width="140px"
                    border-radius="8px"
                    size="md"
                    color="primary.400"
                    font-size="18px"
                    font-weight="500"
                    margin-left="16px"
                    :value="paymentTracking.meta?.pagesize?.toString()"
                    @change="(perPage) => onChangePerPage('PAYMENT_TRACKING', +perPage)"
                  >
                    <option value="5">
                      5 Rows
                    </option>
                    <option value="10">
                      10 Rows
                    </option>
                    <option value="15">
                      15 Rows
                    </option>
                    <option value="20">
                      20 Rows
                    </option>
                  </c-select>
                </c-flex>
              </c-flex>
            </c-box>
            <BaseTable
              :columns="paymentTracking.columns"
              :items="paymentTracking.items"
              :page="paymentTracking.meta?.page"
              :per-page="paymentTracking.meta?.pagesize"
              :total-page="calculatePerPage(paymentTracking?.total, paymentTracking.meta?.pagesize)"
              :is-loading="paymentTracking.isLoading"
              min-height="500px"
              @on-change-page="(page) => onChangePage('PAYMENT_TRACKING', page)"
            />
          </c-box>
        </TransitionObserver>

        <!-- Coupon Tracking -->
        <TransitionObserver
          :with-opacity="false"
          :debounce-delay="3000"
          @on-intersect="fetchOnIntersect('COUPON_TRACKING')"
        >
          <c-box
            display="flex"
            flex-direction="column"
            gap="18px"
            margin-top="6px"
          >
            <c-box
              display="flex"
              justify-content="space-between"
              align-items="center"
            >
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
              >
                Coupon Tracking
              </BaseText>
              <c-flex align="center">
                <BaseText
                  size-mobile="14px-2"
                  size-desktop="16px"
                >
                  Show
                </BaseText>
                <c-select
                  min-width="140px"
                  border-radius="8px"
                  size="md"
                  color="primary.400"
                  font-size="18px"
                  font-weight="500"
                  margin-left="16px"
                  :value="couponTracking.meta?.pagesize?.toString()"
                  @change="(perPage) => onChangePerPage('COUPON_TRACKING', +perPage)"
                >
                  <option value="5">
                    5 Rows
                  </option>
                  <option value="10">
                    10 Rows
                  </option>
                  <option value="15">
                    15 Rows
                  </option>
                  <option value="20">
                    20 Rows
                  </option>
                </c-select>
              </c-flex>
            </c-box>
            <BaseTable
              :columns="couponTracking.columns"
              :items="couponTracking.items"
              :page="couponTracking.meta?.page"
              :per-page="couponTracking.meta?.pagesize"
              :total-page="calculatePerPage(couponTracking?.total, couponTracking.meta?.pagesize)"
              :is-loading="couponTracking.isLoading"
              min-height="500px"
              @on-change-page="(page) => onChangePage('COUPON_TRACKING', page)"
            />
          </c-box>
        </TransitionObserver>

        <!-- Demographic Tracking -->
        <TransitionObserver
          :with-opacity="false"
          :debounce-delay="3000"
          @on-intersect="fetchOnIntersect('DEMOGRAPHIC_TRACKING')"
        >
          <c-box
            display="flex"
            flex-direction="column"
            gap="18px"
            margin-top="6px"
          >
            <c-box
              display="flex"
              justify-content="space-between"
              align-items="center"
            >
              <BaseText
                size-mobile="18px"
                size-desktop="20px"
              >
                Demographic Tracking
              </BaseText>
              <c-flex align="center">
                <BaseText
                  size-mobile="14px-2"
                  size-desktop="16px"
                >
                  Show
                </BaseText>
                <c-select
                  min-width="140px"
                  border-radius="8px"
                  size="md"
                  color="primary.400"
                  font-size="18px"
                  font-weight="500"
                  margin-left="16px"
                  :value="demographicTracking.meta?.pagesize?.toString()"
                  @change="(perPage) => onChangePerPage('DEMOGRAPHIC_TRACKING', +perPage)"
                >
                  <option value="5">
                    5 Rows
                  </option>
                  <option value="10">
                    10 Rows
                  </option>
                  <option value="15">
                    15 Rows
                  </option>
                  <option value="20">
                    20 Rows
                  </option>
                </c-select>
              </c-flex>
            </c-box>
            <c-tabs
              class="dashboard-tab"
              border-color="white"
              color="gray.900"
              :default-index="demographicTracking.meta?.filter"
              @change="onChangeTab"
            >
              <c-tab-list>
                <c-tab
                  width="100%"
                  font-size="18px"
                  font-weight="500"
                >
                  Type Client
                </c-tab>
                <c-tab
                  width="100%"
                  font-size="18px"
                  font-weight="500"
                >
                  Type Paying Clients
                </c-tab>
              </c-tab-list>
            </c-tabs>
            <BaseTable
              :columns="demographicTrackingColumns"
              :items="demographicTracking.items"
              :page="demographicTracking.meta?.page"
              :per-page="demographicTracking.meta?.pagesize"
              :total-page="calculatePerPage(demographicTracking?.total, demographicTracking.meta?.pagesize)"
              :is-loading="demographicTracking.isLoading"
              min-height="500px"
              @on-change-page="(page) => onChangePage('DEMOGRAPHIC_TRACKING', page)"
            />
          </c-box>
        </TransitionObserver>
      </c-box>
    </c-box>
  </c-box>
</template>

<script>
import BaseTable from '@/components/elements/base-table.vue'
import BaseText from '@/components/elements/base-text.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import { mapActions } from 'vuex'
import { formatDateV2 } from '@/utils/format-date'
import _ from 'lodash'
import { formatCurrency } from '@/utils/format-currency'
import BaseButton from '@/components/elements/base-button.vue'
import BaseInputDate from '@/components/elements/base-input-date.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import BaseMenu from '@/components/elements/base-menu.vue'
import TransitionObserver from '@/components/elements/TransitionObserver.vue'

export default {
  name: 'AdminPage',
  components: {
    BaseMenu,
    BaseInputSelect,
    BaseInputDate,
    BaseButton,
    BreadcrumbPath,
    BaseText,
    BaseTable,
    TransitionObserver,
  },
  data() {
    return {
      filterDate: '',
      filterStatus: '',
      filterDatePaymentTracking: '',
      clientTracking: {
        columns: [
          {
            id: 'no',
            label: 'NO',
            type: 'AUTO_INCREMENT',
          },
          {
            id: 'fullName',
            label: 'NAMA KLIEN',
            type: 'TEXT',
          },
          {
            id: 'paymentStatus',
            label: 'STATUS PEMBAYARAN',
            type: 'STATUS_WITH_TEXT',
            itemAlign: 'center',
            // sortable: true,
            customRender: (item) => {
              return {
                status:
                  item?.paymentStatus?.toLowerCase() === 'success'
                    ? 'done'
                    : item?.paymentStatus?.toLowerCase() === 'expire'
                      ? 'failed'
                      : item?.paymentStatus,
                text: item?.transactionTime ? `pada: ${formatDateV2(item?.transactionTime, 2)}` : null,
              }
            },
          },
          {
            id: 'program',
            label: 'PROGRAM',
            type: 'TEXT',
          },
          {
            id: 'nutritionist',
            label: 'AHLI GIZI',
            type: 'TEXT',
          },
          {
            id: 'berakhir',
            label: 'BERAKHIR',
            type: 'TEXT_WITH_SUBTEXT',
            customRender: (item) => {
              return {
                text: formatDateV2(item.programEndDate, 1),
                subtext: isNaN(+item.programEndInDays) ? item.programEndInDays : `${item.programEndInDays} hari lagi`,
              }
            },
            width: '114px',
          },
        ],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
          // sort: {
          //   fullName: 'DESC',
          // },
        },
        total: 0,
        isLoading: true,
      },
      nutritionistsTracking: {
        columns: [
          {
            id: 'no',
            label: 'NO',
            type: 'AUTO_INCREMENT',
          },
          {
            id: 'nutritionistName',
            label: 'NAMA KLIEN',
            type: 'TEXT_WITH_SUBTEXT',
            customRender: (item) => {
              return {
                text: item.nutritionistName,
                subtext: item.programName,
              }
            },
          },
          {
            id: 'adimeNotes',
            label: 'ADIME NOTES',
            type: 'TEXT',
            itemAlign: 'center',
          },
          {
            id: 'duration',
            label: 'DURASI',
            type: 'TEXT',
            customRender: (item) => {
              return item.duration ? `${item.duration} hari` : ''
            },
          },
          {
            id: 'mealPlan',
            label: 'MEAL PLAN CREATED',
            type: 'TEXT',
            customRender: (item) => {
              return item.mealPlan ? formatDateV2(item.mealPlan, 1) : ''
            },
          },
          {
            id: 'followUpNotesPosting',
            label: 'FOLLOW UP NOTES POSTING',
            type: 'TEXT',
          },
          {
            id: 'dietFeedbackPosting',
            label: 'DIET FEEDBACK POSTING',
            type: 'TEXT',
          },
        ],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
        },
        total: 0,
        isLoading: true,
      },
      paymentTracking: {
        columns: [
          {
            id: 'no',
            label: 'NO',
            type: 'AUTO_INCREMENT',
          },
          {
            id: 'purchaseDate',
            label: 'PURCHASE DATE',
            type: 'TEXT',
            customRender: (item) => {
              return item.purchaseDate ? formatDateV2(item.purchaseDate, 1) : ''
            },
          },
          {
            id: 'clientName',
            label: 'NAMA KLIEN',
            type: 'TEXT',
          },
          {
            id: 'email',
            label: 'EMAIL',
            type: 'TEXT',
          },
          {
            id: 'phone',
            label: 'NO TELP',
            type: 'TEXT',
          },
          {
            id: 'amount',
            label: 'AMOUNT',
            type: 'TEXT',
            customRender: (item) => {
              return item.amount ? formatCurrency(item.amount) : ''
            },
          },
        ],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
        },
        total: 0,
        isLoading: true,
      },
      couponTracking: {
        columns: [
          {
            id: 'no',
            label: 'NO',
            type: 'AUTO_INCREMENT',
          },
          {
            id: 'couponName',
            label: 'JENIS KUPON',
            type: 'TEXT',
          },
          {
            id: 'couponCode',
            label: 'KODE KUPON',
            type: 'TEXT',
          },
          {
            id: 'clientName',
            label: 'NAMA KLIEN',
            type: 'TEXT',
          },
          {
            id: 'productName',
            label: 'PROGRAM',
            type: 'TEXT',
          },
          {
            id: 'productPrice',
            label: 'HARGA',
            type: 'TEXT',
            customRender: (item) => {
              return item.productPrice ? formatCurrency(item.productPrice) : ''
            },
          },
          {
            id: 'transactionTime',
            label: 'TANGGAL PEMBELIAN',
            type: 'TEXT',
            customRender: (item) => {
              return item.transactionTime ? `${formatDateV2(item.transactionTime, 3)} WIB` : '-'
            },
          },
        ],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
        },
        total: 0,
        isLoading: true,
      },
      demographicTracking: {
        columns: [],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
          filter: 0,
        },
        total: 0,
        isLoading: true,
      },
      mealPlans: {
        columns: [
          {
            id: 'client.fullName',
            label: 'Nama Klien',
            type: 'TEXT',
            customRender: (item) => {
              return item?.client?.fullName || '-'
            },
          },
          {
            id: 'clientManager.fullName',
            label: 'Ahli Gizi',
            type: 'TEXT',
            customRender: (item) => {
              return item?.clientManager?.fullName || '-'
            },
          },
          {
            id: 'createdAt',
            label: 'Dikirim pada',
            type: 'TEXT',
            customRender: (item) => {
              return item?.createdAt ? formatDateV2(item?.createdAt, 8) : '-'
            },
          },
          {
            id: 'status',
            label: 'Status',
            type: 'CUSTOM2',
          },
          {
            id: 'aksi',
            label: 'Aksi',
            type: 'CUSTOM2',
          },
        ],
        items: [],
        meta: {
          page: 1,
          pagesize: 5,
          filter: 0,
        },
        total: 0,
        isLoading: true,
      },
    }
  },
  computed: {
    demographicTrackingColumns() {
      switch (this.demographicTracking.meta.filter) {
        case 0:
          return [
            {
              id: 'no',
              label: 'NO',
              type: 'AUTO_INCREMENT',
            },
            {
              id: 'country',
              label: 'NEGARA',
              type: 'TEXT',
            },
            {
              id: 'registeredAt',
              label: 'BULAN DAFTAR',
              type: 'TEXT',
            },
            {
              id: 'age',
              label: 'USIA',
              type: 'TEXT',
              customRender: (item) => {
                return item.age ? `${item.age} tahun` : ''
              },
            },
          ]
        case 1:
          return [
            {
              id: 'no',
              label: 'NO',
              type: 'AUTO_INCREMENT',
            },
            {
              id: 'country',
              label: 'NEGARA',
              type: 'TEXT',
            },
            {
              id: 'province',
              label: 'PROVINSI',
              type: 'TEXT',
            },
            {
              id: 'city',
              label: 'KOTA',
              type: 'TEXT',
            },
            {
              id: 'gender',
              label: 'JENIS KELAMIN',
              type: 'TEXT',
              customRender: (item) => {
                if (!item.gender) return '-'
                return item.gender?.toLowerCase() === 'male' ? 'Laki-laki' : 'Perempuan'
              },
            },
            {
              id: 'age',
              label: 'USIA',
              type: 'TEXT',
              customRender: (item) => {
                return item.age ? `${item.age} tahun` : ''
              },
            },
            {
              id: 'job',
              label: 'PEKERJAAN',
              type: 'TEXT',
            },
            {
              id: 'lastEducation',
              label: 'PENDIDIKAN TERAHIR',
              type: 'TEXT',
            },
          ]
        default:
          return []
      }
    },
  },
  watch: {
    'clientTracking.meta': {
      handler: _.debounce(async function(newVal) {
        this.clientTracking.isLoading = true
        const res = await this.loadClientsTracking({
          page: newVal.page,
          perpage: newVal.pagesize,
          transactionDate: this.$route?.query?.client_tracking_transaction_date,
          transactionStatus: this.$route?.query?.client_tracking_transaction_status,
        })
        this.clientTracking.items = res.data
        this.clientTracking.total = res.meta.total
        this.clientTracking.isLoading = false
      }, 500),
      deep: true,
    },
    'nutritionistsTracking.meta': {
      handler: _.debounce(async function(newVal) {
        this.nutritionistsTracking.isLoading = true
        const res = await this.loadNutritionistsTracking({
          page: newVal.page,
          perpage: newVal.pagesize,
        })
        this.nutritionistsTracking.items = res.data
        this.nutritionistsTracking.isLoading = false
      }, 500),
      deep: true,
    },
    'paymentTracking.meta': {
      handler: _.debounce(async function(newVal) {
        this.paymentTracking.isLoading = true
        const res = await this.loadPaymentsTracking({
          page: newVal.page,
          perpage: newVal.pagesize,
          transactionDate: this.$route.query?.payment_tracking_transaction_date,
        })
        this.paymentTracking.items = res.data
        this.paymentTracking.total = res.meta.total
        this.paymentTracking.isLoading = false
      }, 500),
      deep: true,
    },
    'couponTracking.meta': {
      handler: _.debounce(async function(newVal) {
        this.couponTracking.isLoading = true
        const res = await this.loadCouponsTracking({
          page: newVal.page,
          perpage: newVal.pagesize,
        })
        this.couponTracking.items = res.data
        this.couponTracking.isLoading = false
      }, 500),
      deep: true,
    },
    'demographicTracking.meta': {
      handler: _.debounce(async function(newVal) {
        this.demographicTracking.isLoading = true
        const filter = newVal.filter === 1 ? 'paying' : null
        const res = await this.loadDemographicAnalytic({
          page: newVal.page,
          perpage: newVal.pagesize,
          type: filter,
        })
        this.demographicTracking.items = res.data
        this.demographicTracking.total = res.meta.total
        this.demographicTracking.isLoading = false
      }, 500),
      deep: true,
    },
  },
  // mounted() {
  //   this.init()
  // },
  methods: {
    ...mapActions({
      loadClientsTracking: 'admDashboard/listClientsTracking',
      loadNutritionistsTracking: 'admDashboard/listNutritionistsTracking',
      loadPaymentsTracking: 'admDashboard/listPaymentsTracking',
      loadCouponsTracking: 'admDashboard/listCouponsTracking',
      loadDemographicAnalytic: 'admDashboard/listDemographicAnalytic',
      // loadMealPlans: 'admDashboard/listMealPlans',
    }),
    async applyFilterClientTracking() {
      this.$router.replace({
        name: 'admin',
        query: {
          ...this.$route.query,
          client_tracking_transaction_date: this.filterDate,
          client_tracking_transaction_status: this.filterStatus,
        },
        params: { savePosition: true },
      })
      const res = await this.loadClientsTracking({
        page: this.clientTracking.meta.page,
        perpage: this.clientTracking.meta.pagesize,
        transactionDate: this.filterDate,
        transactionStatus: this.filterStatus,
      })
      this.clientTracking.items = res.data
      this.clientTracking.total = res.meta.total
    },
    async applyFilterPaymentTracking() {
      this.$router.replace({
        name: 'admin',
        query: {
          ...this.$route.query,
          payment_tracking_transaction_date: this.filterDatePaymentTracking,
        },
        params: { savePosition: true },
      })
      const res = await this.loadPaymentsTracking({
        page: this.paymentTracking.meta.page,
        perpage: this.paymentTracking.meta.pagesize,
        transactionDate: this.filterDatePaymentTracking,
      })
      this.paymentTracking.items = res.data
      this.paymentTracking.total = res.meta.total
    },
    onChangePerPage(type, perPage) {
      switch (type) {
        case 'CLIENTS_TRACKING':
          this.clientTracking.meta.pagesize = perPage
          this.clientTracking.meta.page = 1
          break
        case 'NUTRITIONIST_TRACKING':
          this.nutritionistsTracking.meta.pagesize = perPage
          this.nutritionistsTracking.meta.page = 1
          break
        case 'PAYMENT_TRACKING':
          this.paymentTracking.meta.pagesize = perPage
          this.paymentTracking.meta.page = 1
          break
        case 'COUPON_TRACKING':
          this.couponTracking.meta.pagesize = perPage
          this.couponTracking.meta.page = 1
          break
        case 'DEMOGRAPHIC_TRACKING':
          this.demographicTracking.meta.pagesize = perPage
          this.demographicTracking.meta.page = 1
          break
        default:
          break
      }
    },
    onChangeSort(type, sort) {
      switch (type) {
        case 'NUTRITIONIST_TRACKING':
          this.nutritionistsTracking.meta.sort = sort
          break
        case 'CLIENTS_TRACKING':
          this.clientTracking.meta.sort = sort
          break
        default:
          break
      }
    },
    onChangePage(type, page) {
      switch (type) {
        case 'CLIENTS_TRACKING':
          this.clientTracking.meta.page = page
          break
        case 'NUTRITIONIST_TRACKING':
          this.nutritionistsTracking.meta.page = page
          break
        case 'PAYMENT_TRACKING':
          this.paymentTracking.meta.page = page
          break
        case 'COUPON_TRACKING':
          this.couponTracking.meta.page = page
          break
        case 'DEMOGRAPHIC_TRACKING':
          this.demographicTracking.meta.page = page
          break
        case 'MEAL_PLANS':
          this.mealPlans.meta.page = page
          break
        default:
          break
      }
    },
    calculatePerPage(totalData, perPage) {
      return Math.ceil(totalData / perPage)
    },
    onChangeTab(tab) {
      this.demographicTracking.items = []
      this.demographicTracking.meta.filter = tab
    },
    async init() {
      this.filterDate = this.$route?.query?.client_tracking_transaction_date
      this.filterStatus = this.$route?.query?.client_tracking_transaction_status

      this.filterDatePaymentTracking = this.$route?.query?.payment_tracking_transaction_date

      const [res, res2, res3, res4, res5] = await Promise.all([
        this.loadClientsTracking({
          page: this.clientTracking.meta.page,
          perpage: this.clientTracking.meta.pagesize,
          transactionDate: this.$route?.query?.client_tracking_transaction_date,
          transactionStatus: this.$route?.query?.client_tracking_transaction_status,
        }),
        this.loadNutritionistsTracking({
          page: this.nutritionistsTracking.meta.page,
          perpage: this.nutritionistsTracking.meta.pagesize,
        }),
        this.loadPaymentsTracking({
          page: this.paymentTracking.meta.page,
          perpage: this.paymentTracking.meta.pagesize,
          transactionDate: this.$route?.query?.payment_tracking_transaction_date,
        }),
        this.loadCouponsTracking({
          page: this.couponTracking.meta.page,
          perpage: this.couponTracking.meta.pagesize,
        }),
        this.loadDemographicAnalytic({
          page: this.demographicTracking.meta.page,
          perpage: this.demographicTracking.meta.pagesize,
        }),
        this.loadMealPlans({
          page: this.mealPlans.meta.page,
          perpage: this.mealPlans.meta.pagesize,
        }),
      ])

      this.clientTracking.items = res.data
      this.clientTracking.total = res.meta.total

      this.nutritionistsTracking.items = res2.data
      this.nutritionistsTracking.total = res2.meta.total

      this.paymentTracking.items = res3.data
      this.paymentTracking.total = res3.meta.total

      this.couponTracking.items = res4.data
      this.couponTracking.total = res4.meta.total

      this.demographicTracking.items = res5.data
      this.demographicTracking.total = res5.meta.total
    },
    async fetchOnIntersect(type) {
      switch (type) {
        case 'CLIENTS_TRACKING': {
          if (this.clientTracking.items.length > 0) return
  
          this.clientTracking.isLoading = true
          const res = await this.loadClientsTracking({
            page: this.clientTracking.meta.page,
            perpage: this.clientTracking.meta.pagesize,
            transactionDate: this.$route?.query?.client_tracking_transaction_date,
            transactionStatus: this.$route?.query?.client_tracking_transaction_status,
          })
  
          this.clientTracking.items = res.data
          this.clientTracking.total = res.meta.total
          this.clientTracking.isLoading = false
          break
        }
        case 'NUTRITIONIST_TRACKING': {
          if (this.nutritionistsTracking.items.length > 0) return
      
          this.filterDate = this.$route?.query?.client_tracking_transaction_date
          this.filterStatus = this.$route?.query?.client_tracking_transaction_status
  
          this.nutritionistsTracking.isLoading = true
          const res = await this.loadNutritionistsTracking({
            page: this.nutritionistsTracking.meta.page,
            perpage: this.nutritionistsTracking.meta.pagesize,
          })
      
          this.nutritionistsTracking.items = res.data
          this.nutritionistsTracking.total = res.meta.total
          this.nutritionistsTracking.isLoading = false
          break
        }
        case 'PAYMENT_TRACKING': {
  
          if (this.paymentTracking.items.length > 0) return
  
          this.filterDatePaymentTracking = this.$route?.query?.payment_tracking_transaction_date
  
          this.paymentTracking.isLoading = true
          const res = await this.loadPaymentsTracking({
            page: this.paymentTracking.meta.page,
            perpage: this.paymentTracking.meta.pagesize,
            transactionDate: this.$route?.query?.payment_tracking_transaction_date,
          })
  
          this.paymentTracking.items = res.data
          this.paymentTracking.total = res.meta.total
          this.paymentTracking.isLoading = false
          break
        }
        case 'COUPON_TRACKING': {
          if (this.couponTracking.items.length > 0) return
      
          this.couponTracking.isLoading = true
          const res = await this.loadCouponsTracking({
            page: this.couponTracking.meta.page,
            perpage: this.couponTracking.meta.pagesize,
          })
      
          this.couponTracking.items = res.data
          this.couponTracking.total = res.meta.total
          this.couponTracking.isLoading = false
          break
        }
        case 'DEMOGRAPHIC_TRACKING': {
          if (this.demographicTracking.items.length > 0) return
      
          this.demographicTracking.isLoading = true
          const res = await this.loadDemographicAnalytic({
            page: this.demographicTracking.meta.page,
            perpage: this.demographicTracking.meta.pagesize,
          })
      
          this.demographicTracking.items = res.data
          this.demographicTracking.total = res.meta.total
          this.demographicTracking.isLoading = false
          break
        }
        default:
          break
      }
    },
  },
}
</script>

<style scoped>
.dashboard-tab::v-deep [aria-selected='true'] {
  border-bottom: 2px solid #008c81;
  color: #008c81;
}
</style>
