var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "border-radius": "12px",
      "background-color": "white",
      "border": "1px solid #EDF2F7"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "overflow-x": "auto"
    }
  }, [_c('c-box', {
    staticClass: "base-table__table",
    attrs: {
      "as": "table",
      "width": "100%",
      "border": "0",
      "cellspacing": "0",
      "cellpadding": "0",
      "font-size": "14px"
    }
  }, [_c('c-box', {
    staticClass: "base-table__thead",
    attrs: {
      "as": "thead",
      "color": _vm.color === 'primary' ? 'primary.400' : 'neutral.superDarkGray',
      "background-color": _vm.color === 'primary' ? 'primary.50' : 'secondary.300',
      "position": "sticky"
    }
  }, [_c('c-box', {
    staticClass: "base-table__tr",
    attrs: {
      "as": "tr"
    }
  }, _vm._l(_vm.columns, function (column, i) {
    var _vm$sort, _vm$sort$column$id, _vm$sort2, _vm$sort2$column$id;

    return _c('c-box', {
      key: column.id,
      class: {
        'base-table__th': true,
        'sortable': column.sortable
      },
      attrs: {
        "as": "th",
        "font-size": "14px",
        "font-weight": "400",
        "font-family": "Roboto, sans-serif, Arial",
        "padding": "10.5px 14px",
        "text-align": column.align || 'left',
        "position": column !== null && column !== void 0 && column.frozen ? 'sticky' : 'relative',
        "inset-inline-start": column !== null && column !== void 0 && column.frozen ? _vm.insetInlineStart(i, _vm.columns) : '',
        "background-color": _vm.color === 'primary' ? 'primary.50' : 'secondary.300',
        "z-index": column !== null && column !== void 0 && column.frozen ? '2' : '1',
        "box-shadow": column !== null && column !== void 0 && column.frozen ? '3px 0 3px -3px #C4C4C4' : '',
        "border-right": _vm.lastIndexColumnFrozen === i ? '1px solid #e5e5e5' : ''
      },
      on: {
        "click": function click($event) {
          return _vm.handleChangeSort(column.id, column.sortable);
        }
      }
    }, [_c('c-box', {
      attrs: {
        "width": column.width || '100%',
        "display": "flex",
        "align-items": "center",
        "gap": "8px"
      }
    }, [_c('span', [_vm._v(_vm._s(column.label))]), column.sortable ? _c('c-box', [((_vm$sort = _vm.sort) === null || _vm$sort === void 0 ? void 0 : (_vm$sort$column$id = _vm$sort[column === null || column === void 0 ? void 0 : column.id]) === null || _vm$sort$column$id === void 0 ? void 0 : _vm$sort$column$id.toUpperCase()) === 'ASC' ? _c('span', [_vm._v(" ▲ ")]) : ((_vm$sort2 = _vm.sort) === null || _vm$sort2 === void 0 ? void 0 : (_vm$sort2$column$id = _vm$sort2[column === null || column === void 0 ? void 0 : column.id]) === null || _vm$sort2$column$id === void 0 ? void 0 : _vm$sort2$column$id.toUpperCase()) === 'DESC' ? _c('span', [_vm._v(" ▼ ")]) : _c('c-box', {
      attrs: {
        "as": "span",
        "color": "neutral.lightGray"
      }
    }, [_vm._v(" ▼ ")])], 1) : _vm._e()], 1)], 1);
  }), 1)], 1), _c('c-box', {
    staticClass: "base-table__tbody",
    attrs: {
      "as": "tbody",
      "position": "relative",
      "background-color": _vm.color === 'primary' ? 'unset' : 'secondary.50'
    }
  }, [_vm.isLoading ? _c('c-box', {
    staticClass: "base-table__tr",
    attrs: {
      "height": _vm.items.length === 0 ? _vm.minHeight : 'unset',
      "as": "tr"
    }
  }, [_c('c-flex', {
    staticClass: "base-table__td",
    attrs: {
      "as": "td",
      "position": "absolute",
      "width": "100%",
      "height": "100%",
      "backdrop-filter": "blur(2px)",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "z-index": "10",
      "colspan": "100%",
      "cursor": "wait",
      "background-color": _vm.color === 'primary' ? '#FFF' : 'secondary.50'
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Memuat data... ")])], 1)], 1) : _vm.items.length <= 0 ? _c('c-box', {
    staticClass: "base-table__tr",
    attrs: {
      "height": _vm.items.length === 0 ? _vm.minHeight : 'unset',
      "as": "tr"
    }
  }, [_c('c-flex', {
    staticClass: "base-table__td",
    attrs: {
      "as": "td",
      "position": "absolute",
      "width": "100%",
      "height": "100%",
      "backdrop-filter": "blur(2px)",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "z-index": "10",
      "colspan": "100%",
      "cursor": "wait",
      "background-color": _vm.color === 'primary' ? '#FFF' : 'secondary.50'
    }
  }, [_c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Data tidak ditemukan ")])], 1)], 1) : _vm._e(), _vm._l(_vm.items, function (item, itemIdx) {
    return _c('c-box', {
      key: itemIdx,
      staticClass: "base-table__tr",
      attrs: {
        "as": "tr"
      }
    }, _vm._l(_vm.columns, function (column, columnIdx) {
      var _column$customRender, _column$customRender2, _item$column$id, _item$column$id2, _column$customRender3, _column$customRender4, _column$customRender5;

      return _c('c-box', {
        key: columnIdx,
        staticClass: "base-table__td",
        attrs: {
          "as": "td",
          "padding": "10.5px 14px",
          "text-align": column.itemAlign || 'left',
          "position": column !== null && column !== void 0 && column.frozen ? 'sticky' : 'relative',
          "inset-inline-start": column !== null && column !== void 0 && column.frozen ? _vm.insetInlineStart(columnIdx, _vm.columns) : '',
          "background-color": _vm.color === 'primary' ? '#FFF' : 'secondary.50',
          "z-index": column !== null && column !== void 0 && column.frozen ? '2' : '1',
          "box-shadow": column !== null && column !== void 0 && column.frozen ? '3px 0 3px -3px #C4C4C4' : '',
          "border-right": _vm.lastIndexColumnFrozen === columnIdx ? '1px solid #e5e5e5' : ''
        }
      }, [column.type === 'AUTO_INCREMENT' ? _c('c-text', [_vm._v(" " + _vm._s((_vm.page - 1) * _vm.perPage + (itemIdx + 1)) + " ")]) : _vm._e(), column.type === 'TEXT' ? _c('c-text', [column !== null && column !== void 0 && column.customRender ? _c('c-text', {
        attrs: {
          "as": "span"
        }
      }, [_vm._v(" " + _vm._s((column === null || column === void 0 ? void 0 : column.customRender(item)) || '-') + " ")]) : _c('c-text', {
        attrs: {
          "as": "span"
        }
      }, [_vm._v(" " + _vm._s(item[column.id] || '-') + " ")])], 1) : _vm._e(), column.type === 'TEXT_WITH_SUBTEXT' ? _c('c-box', [column !== null && column !== void 0 && column.customRender ? _c('c-box', [_c('c-text', [_vm._v(" " + _vm._s((column === null || column === void 0 ? void 0 : (_column$customRender = column.customRender(item)) === null || _column$customRender === void 0 ? void 0 : _column$customRender.text) || '-') + " ")]), _c('c-text', {
        attrs: {
          "color": "gray.900"
        }
      }, [_vm._v(" " + _vm._s((column === null || column === void 0 ? void 0 : (_column$customRender2 = column.customRender(item)) === null || _column$customRender2 === void 0 ? void 0 : _column$customRender2.subtext) || '-') + " ")])], 1) : _c('c-box', [_c('c-text', [_vm._v(" " + _vm._s(((_item$column$id = item[column.id]) === null || _item$column$id === void 0 ? void 0 : _item$column$id.text) || '-') + " ")]), _c('c-text', {
        attrs: {
          "color": "gray.900"
        }
      }, [_vm._v(" " + _vm._s(((_item$column$id2 = item[column.id]) === null || _item$column$id2 === void 0 ? void 0 : _item$column$id2.subtext) || '-') + " ")])], 1)], 1) : _vm._e(), column.type === 'STATUS' ? _c('c-box', {
        attrs: {
          "display": "inline-block"
        }
      }, [item[column.id] === '-' ? _c('c-text', [_vm._v(" " + _vm._s(item[column.id]) + " ")]) : _c('ChipStatus', {
        attrs: {
          "status": item[column.id],
          "screen": "mobile-only"
        }
      })], 1) : _vm._e(), column.type === 'STATUS_WITH_TEXT' ? _c('c-box', {
        attrs: {
          "display": "inline-block"
        }
      }, [item[column.id] === '-' ? _c('c-text', [_vm._v(" " + _vm._s(item[column.id]) + " ")]) : _vm._e(), column !== null && column !== void 0 && column.customRender ? _c('c-box', [_c('ChipStatus', {
        attrs: {
          "status": column === null || column === void 0 ? void 0 : (_column$customRender3 = column.customRender(item)) === null || _column$customRender3 === void 0 ? void 0 : _column$customRender3.status,
          "screen": "mobile-only"
        }
      }), !!(column !== null && column !== void 0 && (_column$customRender4 = column.customRender(item)) !== null && _column$customRender4 !== void 0 && _column$customRender4.text) ? _c('c-text', {
        attrs: {
          "color": "superDarkGray",
          "display": "block",
          "margin-top": "5px"
        }
      }, [_vm._v(" " + _vm._s((column === null || column === void 0 ? void 0 : (_column$customRender5 = column.customRender(item)) === null || _column$customRender5 === void 0 ? void 0 : _column$customRender5.text) || '-') + " ")]) : _vm._e()], 1) : _c('c-text')], 1) : _vm._e(), column.type === 'CUSTOM2' ? _c('c-box', {
        attrs: {
          "display": "inline-block"
        }
      }, [_vm._t(column.id, function () {
        return [_vm._v(" aasdasdasd ")];
      }, {
        "item": item
      })], 2) : _vm._e()], 1);
    }), 1);
  })], 2)], 1)], 1), _c('c-box', {
    attrs: {
      "font-size": "14px",
      "font-weight": "400",
      "font-family": "Roboto, sans-serif, Arial",
      "padding": "12px 0",
      "border-top": "2px solid #CBD5E0",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "background-color": _vm.color === 'primary' ? 'unset' : 'secondary.50'
    }
  }, [_c('BasePagination', {
    attrs: {
      "color": _vm.color,
      "page": _vm.page,
      "total-page": _vm.totalPage || 1
    },
    on: {
      "on-change": _vm.handleChangePage
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }