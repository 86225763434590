<template>
  <c-box
    position="relative"
    width="100%"
    border-radius="12px"
    background-color="white"
    border="1px solid #EDF2F7"
  >
    <c-box
      width="100%"
      overflow-x="auto"
    >
      <c-box
        class="base-table__table"
        as="table"
        width="100%"
        border="0"
        cellspacing="0"
        cellpadding="0"
        font-size="14px"
      >
        <c-box
          class="base-table__thead"
          as="thead"
          :color="color === 'primary' ? 'primary.400' : 'neutral.superDarkGray'"
          :background-color="color === 'primary' ? 'primary.50' : 'secondary.300'"
          position="sticky"
        >
          <c-box
            class="base-table__tr"
            as="tr"
          >
            <c-box
              v-for="(column, i) in columns"
              :key="column.id"
              :class="{
                'base-table__th': true,
                'sortable': column.sortable,
              }"
              as="th"
              font-size="14px"
              font-weight="400"
              font-family="Roboto, sans-serif, Arial"
              padding="10.5px 14px"
              :text-align="column.align || 'left'"
              :position="column?.frozen ? 'sticky' : 'relative'"
              :inset-inline-start="column?.frozen ? insetInlineStart(i, columns) : ''"
              :background-color="color === 'primary' ? 'primary.50' : 'secondary.300'"
              :z-index="column?.frozen ? '2' : '1'"
              :box-shadow="column?.frozen ? '3px 0 3px -3px #C4C4C4' : ''"
              :border-right="lastIndexColumnFrozen === i ? '1px solid #e5e5e5' : ''"
              @click="handleChangeSort(column.id, column.sortable)"
            >
              <c-box
                :width="column.width || '100%'"
                display="flex"
                align-items="center"
                gap="8px"
              >
                <span>{{ column.label }}</span>
                <c-box
                  v-if="column.sortable"
                >
                  <span 
                    v-if="sort?.[column?.id]?.toUpperCase() === 'ASC'"
                  >
                    ▲
                  </span>
                  <span 
                    v-else-if="sort?.[column?.id]?.toUpperCase() === 'DESC'"
                  >
                    ▼
                  </span>
                  <c-box
                    v-else
                    as="span"
                    color="neutral.lightGray"
                  >
                    ▼
                  </c-box>
                </c-box>
              </c-box>
            </c-box>
          </c-box>
        </c-box>
        <c-box
          class="base-table__tbody"
          as="tbody"
          position="relative"
          :background-color="color === 'primary' ? 'unset' : 'secondary.50'"
        >
          <c-box
            v-if="isLoading"
            class="base-table__tr"
            :height="items.length === 0 ? minHeight : 'unset'"
            as="tr"
          >
            <c-flex
              class="base-table__td"
              as="td"
              position="absolute"
              width="100%"
              height="100%"
              backdrop-filter="blur(2px)"
              flex-direction="column"
              justify-content="center"
              align-items="center"
              z-index="10"
              colspan="100%"
              cursor="wait"
              :background-color="color === 'primary' ? '#FFF' : 'secondary.50'"
            >
              <c-spinner
                thickness="4px"
                speed="0.65s"
                empty-color="green.200"
                color="primary.400"
                size="xl"
              />
              <c-text
                color="primary.400"
                font-weight="500"
              >
                Memuat data...
              </c-text>
            </c-flex>
          </c-box>
          <c-box
            v-else-if="items.length <= 0"
            class="base-table__tr"
            :height="items.length === 0 ? minHeight : 'unset'"
            as="tr"
          >
            <c-flex
              class="base-table__td"
              as="td"
              position="absolute"
              width="100%"
              height="100%"
              backdrop-filter="blur(2px)"
              flex-direction="column"
              justify-content="center"
              align-items="center"
              z-index="10"
              colspan="100%"
              cursor="wait"
              :background-color="color === 'primary' ? '#FFF' : 'secondary.50'"
            >
              <c-text
                color="primary.400"
                font-weight="500"
              >
                Data tidak ditemukan
              </c-text>
            </c-flex>
          </c-box>
          <c-box
            v-for="(item, itemIdx) in items"
            :key="itemIdx"
            class="base-table__tr"
            as="tr"
          >
            <c-box
              v-for="(column, columnIdx) in columns"
              :key="columnIdx"
              class="base-table__td"
              as="td"
              padding="10.5px 14px"
              :text-align="column.itemAlign || 'left'"
              :position="column?.frozen ? 'sticky' : 'relative'"
              :inset-inline-start="column?.frozen ? insetInlineStart(columnIdx, columns) : ''"
              :background-color="color === 'primary' ? '#FFF' : 'secondary.50'"
              :z-index="column?.frozen ? '2' : '1'"
              :box-shadow="column?.frozen ? '3px 0 3px -3px #C4C4C4' : ''"
              :border-right="lastIndexColumnFrozen === columnIdx ? '1px solid #e5e5e5' : ''"
            >
              <c-text
                v-if="column.type === 'AUTO_INCREMENT'"
              >
                {{ (page-1) * perPage + (itemIdx+1) }}
              </c-text>
              <c-text
                v-if="column.type === 'TEXT'"
              >
                <c-text
                  v-if="column?.customRender"
                  as="span"
                >
                  {{ column?.customRender(item) || '-' }}
                </c-text>
                <c-text
                  v-else
                  as="span"
                >
                  {{ item[column.id] || '-' }}
                </c-text>
              </c-text>
              <c-box
                v-if="column.type === 'TEXT_WITH_SUBTEXT'"
              >
                <c-box
                  v-if="column?.customRender"
                >
                  <c-text>
                    {{ column?.customRender(item)?.text || '-' }}
                  </c-text>
                  <c-text color="gray.900">
                    {{ column?.customRender(item)?.subtext || '-' }}
                  </c-text>
                </c-box>
                <c-box
                  v-else
                >
                  <c-text>
                    {{ item[column.id]?.text || '-' }}
                  </c-text>
                  <c-text color="gray.900">
                    {{ item[column.id]?.subtext || '-' }}
                  </c-text>
                </c-box>
              </c-box>
              <c-box
                v-if="column.type === 'STATUS'"
                display="inline-block"
              >
                <c-text
                  v-if="item[column.id] === '-'"
                >
                  {{ item[column.id] }}
                </c-text>
                <ChipStatus
                  v-else
                  :status="item[column.id]"
                  screen="mobile-only"
                />
              </c-box>
              <c-box
                v-if="column.type === 'STATUS_WITH_TEXT'"
                display="inline-block"
              >
                <c-text
                  v-if="item[column.id] === '-'"
                >
                  {{ item[column.id] }}
                </c-text>
                <c-box
                  v-if="column?.customRender"
                >
                  <ChipStatus
                    :status="column?.customRender(item)?.status"
                    screen="mobile-only"
                  />
                  <c-text
                    v-if="!!column?.customRender(item)?.text"
                    color="superDarkGray"
                    display="block"
                    margin-top="5px"
                  >
                    {{ column?.customRender(item)?.text || '-' }}
                  </c-text>
                </c-box>
                <c-text v-else />
              </c-box>
              <c-box
                v-if="column.type === 'CUSTOM2'"
                display="inline-block"
              >
                <slot
                  :name="column.id"
                  :item="item"
                >
                  aasdasdasd
                </slot>
                <!--                <slot>{{ item[column.id] }}</slot>-->
              </c-box>
            </c-box>
          </c-box>
        </c-box>
      </c-box>
    </c-box>
    <c-box
      font-size="14px"
      font-weight="400"
      font-family="Roboto, sans-serif, Arial"
      padding="12px 0"
      border-top="2px solid #CBD5E0"
      display="flex"
      justify-content="center"
      align-items="center"
      :background-color="color === 'primary' ? 'unset' : 'secondary.50'"
    >
      <BasePagination
        :color="color"
        :page="page"
        :total-page="totalPage || 1"
        @on-change="handleChangePage"
      />
    </c-box>
  </c-box>
</template>

<script>
import BasePagination from '@/components/elements/base-pagination.vue'
import ChipStatus from '@/components/elements/chip-status.vue'

export default {
  name: 'BaseTable',
  components: {
    ChipStatus,
    BasePagination,
  },
  props: {
    sort: {
      type: Object,
      default: () => ({}),
    },
    columns: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 5,
    },
    totalPage: {
      type: Number,
      default: 13,
    },
    color: {
      type: String,
      default: 'primary',
      validator(value) {
        return ['primary', 'secondary'].includes(value)
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: String,
      default: '100px',
    },
  },
  emits: [
    'on-change-page',
    'on-change-sort',
  ],
  computed: {
    lastIndexColumnFrozen() {
      return this.columns.findLastIndex(it => it?.frozen === true)
    },
  },
  methods: {
    handleChangePage(currentPage) {
      this.$emit('on-change-page', currentPage)
    },
    handleChangeSort(columnId, sortable) {
      if (!sortable) {
        return
      }
      const sort = this.sort[columnId]
      let newSort = 'ASC'
      if (sort?.toUpperCase() === 'ASC') {
        newSort = 'DESC'
      }
      this.$emit('on-change-sort', {
        // ...this.sort, 
        [columnId]: newSort,
      })
    },
    insetInlineStart(index, columns) {
      if (index === 0) return '0px'
      let inlineStart = '0px'
      columns.forEach((col, i) => {
        if (col?.frozen && i < index) {
          inlineStart = inlineStart + ` + 28px + ${columns[index - 1]?.width || 0}`
        }
      })
      return `calc(${inlineStart})`
    },
  },
}
</script>

<style scoped>
.base-table__table::v-deep {
  border-collapse: separate;
  border: solid #EDF2F7 1px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.base-table__tbody::v-deep .base-table__tr:not(:last-child) .base-table__td {
  border-bottom: solid #CBD5E0 2px;
}

.base-table__th.sortable:hover {
  filter: brightness(0.9);
  font-weight: 500;
  cursor: pointer;
}

.base-table__th.sortable:active {
  filter: brightness(0.8);
  cursor: pointer;
}
</style>
